import React, { useState } from "react"
import Modal from "js/includes/components/Modal"
import { validations } from "js/includes/common/utils"
import { useForm } from "js/includes/common/hooks"
import MfaSwitch from "./MfaSwitch"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function TwoFactorAuthModal({ mfaResponse, onSubmit, onCancel, onClose, unmount }) {
  const [submitting, setSubmitting] = useState(false)
  const [mfaType, setMfaType] = useState(mfaResponse.mfaType)

  const {
    values: { mfaCode },
    validation,
    onChange,
    validateForm,
    resetForm,
  } = useForm({
    fields: {
      mfaCode: "",
    },
    validate: {
      mfaCode: validations.mfaCodeOnlySixDigits,
    },
  })

  async function handleSubmit(queryParams) {
    if (mfaType === "U2F_TOKEN" || validateForm()) {
      setSubmitting(true)
      await onSubmit(queryParams)
      setSubmitting(false)
      close()
    }
  }

  function handleOnSubmit(e) {
    e.preventDefault()
    handleSubmit({ mfaCode })
  }

  function close() {
    onClose()
    unmount()
  }

  function cancel() {
    onClose()
    onCancel()
    unmount()
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Modal
        overflow
        title={localizationKey("Verify MFA")}
        saveText={localizationKey("Continue")}
        closeText={localizationKey("Cancel")}
        submittable={mfaType !== "U2F_TOKEN"}
        disabledSaveBtn={submitting}
        close={cancel}
      >
        <MfaSwitch
          {...{
            mfaResponse,
            validation,
            handleSubmit,
            mfaType,
            mfaCode,
            setMfaType,
            onChange,
            resetForm,
            validateForm,
            showLabel: true,
            onClose,
            setLoading: setSubmitting,
          }}
        />
      </Modal>
    </form>
  )
}
